import { Outlet } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import { Amplify } from "aws-amplify";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../aws-exports";
import { TitleContext } from "../context";
import { useEffect, useState } from "react";

Amplify.configure(awsExports);

const Root = ({ signOut, user }) => {
  const [title, setTitle] = useState("Uploads list");
  const [authPreviousStatus, setAuthPreviousStatus] = useState("");
  const { authStatus } = useAuthenticator(context => [context.authStatus]);

  useEffect(() => {
    // Check if the user authenticated successfully: unauthenticated => authenticated
    if (authPreviousStatus === "unauthenticated" && authStatus === "authenticated") {
      console.log('[Auth]: User SignIn successfully!');
      window.location.reload(true);
    }
    setAuthPreviousStatus(authStatus);
  },[authPreviousStatus, authStatus]);

  return (
    <Authenticator hideSignUp>
      <TitleContext.Provider value={{ title, setTitle }}>
        <div className="App">
          <div className="navWrapper" id="nav">
            <Menu className="nav">
              <Menu.Item>
                <a href={`/upload`}>
                  <Icon name="home" />
                </a>
              </Menu.Item>
              <Menu.Item fitted="vertically" className="titleWrapper">
                Kanzi Web Player - {title}
              </Menu.Item>
            </Menu>
          </div>
          <div className="contentWrapper">
            <Outlet test="123" />
          </div>
        </div>
      </TitleContext.Provider>
    </Authenticator>
  );
};

export default Root;
