import { Amplify } from "@aws-amplify/core";
import { Storage } from "@aws-amplify/storage";

import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);

//configure Storage with config for unauthenticated storage access
Storage.configure({ level: "public" });

export const getKanziPlayerReleaseVersion = async (kzbPlayerVersion) => {
  const kanziPlayerReleaseVersionPath = 'players/KanziPlayerReleaseVersion.json';
  let kanziPlayerReleaseVersionRequest;
  try {
    kanziPlayerReleaseVersionRequest = await Storage.get(kanziPlayerReleaseVersionPath, {
      download: true,
    });
  
    if (kanziPlayerReleaseVersionRequest.$metadata.httpStatusCode !== 200) {
      const errorMessage = `Failed to download KanziPlayerReleaseVersion file from address: ${kanziPlayerReleaseVersionPath}`;
      console.error(errorMessage);
      return null;
    }  
  } catch (error) {
    const errorMessage = `Failed to download KanziPlayerReleaseVersion file from address: ${kanziPlayerReleaseVersionPath}`;
    console.error(errorMessage);
    return null;
  }

  const kanziPlayerReleaseVersion = await new Response(kanziPlayerReleaseVersionRequest.Body).json();
  console.log('kanziPlayerReleaseVersion: ', kanziPlayerReleaseVersion);

  return kanziPlayerReleaseVersion[kzbPlayerVersion] ?? null;
}

export const getKzbVersion = (uInt8Array) => {
  return (
    uInt8Array[4] +
    uInt8Array[5] * 256 +
    uInt8Array[6] * 65536 +
    uInt8Array[7] * 16777216
  );
}

export const getBundlePlayerVersion = async (file) => {
  const fileContentUInt8Array = new Uint8Array(await file.arrayBuffer());
  const kzbVersion = getKzbVersion(fileContentUInt8Array);
  return {
    name: file.name,
    version: kzbVersion,
  }
}

export const getKzbNameFromApplicationCfg = (applicationCfgData) => {
  let binaryNameFromApplicationCfg = null;
  if (applicationCfgData) {
    let cfgDictionary = {};
    const lines = applicationCfgData.split('\n');
    for (const line of lines) {
      const parts = line.split('=', 2);
      if (parts.length === 2) {
        const key = parts[0].trim();
        const value = parts[1].trim().replaceAll('"', '').replaceAll(';', '');
        cfgDictionary[key] = value;
      }
    }
    // Assigns undefined, if missing.
    binaryNameFromApplicationCfg = cfgDictionary.BinaryName;
  }
  return binaryNameFromApplicationCfg ?? '';
}

export const readFileAsText = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      resolve(e.target.result);
    };
    fileReader.onerror = (e) => {
      reject(e);
    };
    fileReader.readAsText(file);
  });
}

export const writeContentToFile = (content, contentType = 'text/plain') => {
  // Convert the string data to a Blob
  const blob = new Blob([content], { type: contentType});

  // Create a File object from the Blob
  const file = new File([blob], 'application.cfg', { type: contentType });
  return file;
}

export const checkIfFileContentIsPresent = async (file, checkContent) => {
  let isBinaryNameRowAvailable = false;

  // Read the content of the application.cfg file
  const fileContent = await readFileAsText(file);

  // Check if the file content contains a line that starts with "BinaryName="
  const lines = fileContent.split('\n');
  for (const line of lines) {
    if (line.trim().toLowerCase().startsWith(checkContent.toLowerCase())) {
      isBinaryNameRowAvailable = true;
      break;
    }
  }
  return isBinaryNameRowAvailable;
}

export const getBundleInfo = async (files, errorHandler = (errorMessage) => {}) => {
  let kzbFileName = "";
  let cfgFileName = "";
  let kzbFileCount = 0;

  const kzbFiles = files.filter((file) => file.name.endsWith(".kzb"));
  const kzbVersionPromises = kzbFiles.map(async (file) => getBundlePlayerVersion(file));
  const kzbVersion = await Promise.all(kzbVersionPromises);
  const versionsArray = kzbVersion.map((item) => item.version);
  const uniqueVersions = [...new Set(versionsArray)];

  if (kzbFiles.length === 0){
    errorHandler(`No .kzb files available.`);
    return;
  }

  if (uniqueVersions.length > 1) {
    errorHandler(`Found multiple .kzb files in the bundle with different versions: ${uniqueVersions.join()}`);
    return;
  }

  const [uniqueVersion] = uniqueVersions;
  const kzbPlayerVersion = await getKanziPlayerReleaseVersion(uniqueVersion);
  if (!kzbPlayerVersion) {
    errorHandler(`Unable to match the kzb file version ${uniqueVersion} with a compatible player version`);
    return;
  }

  const fileNames = files.map((file) => {
    const { name } = file;

    if (name.endsWith(".kzb")) {
      if (kzbFileCount === 0) {
        kzbFileName = name.replace(/\.[^/.]+$/, "");
      }
      kzbFileCount += 1;
    } else if (name.endsWith(".kzb.cfg")) {
      cfgFileName = name.split(".")[0];
    }
    return name;
  });

  switch (kzbFileCount) {
    case 0:
      errorHandler("No .kzb file in the bundle, you need at least one.");
      break;
    case 1:
      return {
        name: kzbFileName,
        fileNames,
        bundleVersion: uniqueVersion,
        playerVersion: kzbPlayerVersion,
        nameSource: ".kzb",
      };

    default:
      return {
        name: cfgFileName,
        fileNames,
        nameSource: ".kzb.cfg",
      };
  }
};

export const generateManifestFile = async (fileList) => {
  let isManifestFileAvailable = false;
  let manifestContent = [];
  for (let i = 0; i < fileList.length; i++) {
    manifestContent.push(fileList[i].name);
    if (fileList[i].name.toLowerCase() === "manifest.txt") {
      isManifestFileAvailable = true;
    }
  }    
  if (!isManifestFileAvailable) {
    const manifestData = manifestContent.join(";");
    console.log("Auto-generating manifest.txt =>", manifestData);

    // Convert the string data to a Blob
    const blob = new Blob([manifestData], { type: 'text/plain' });

    // Create a File object from the Blob
    const file = new File([blob], 'manifest.txt', { type: 'text/plain' });

    return file
  } else {
    console.log("Skip manifest.txt Auto-generation, user selected file for upload.");
    return null;
  }
}

export const generateApplicationCfg = async (fileList) => {
  let isAppCfgFileAvailable = "";
  let isAppCfgFileBinaryNameRowAvailable = false;
  let isKzbCfgFileAvailable = "";
  let isKzbFileAvailable = "";

  // loop through the file list to check existing conditions
  for (let i = 0; i < fileList.length; i++) {
    const fileName = fileList[i].name;
    const file = fileList[i];

    if (fileName.toLowerCase() === "application.cfg") {
      isAppCfgFileAvailable = fileName;
      isAppCfgFileBinaryNameRowAvailable = await checkIfFileContentIsPresent(file, 'BinaryName');
    } else if (fileName.toLowerCase().endsWith(".kzb.cfg")) {
      isKzbCfgFileAvailable = fileName;
    } else if (fileName.toLowerCase().endsWith(".kzb")) {
      isKzbFileAvailable = fileName;
    }
  }

  if (!isAppCfgFileAvailable) {
    let AppCfgData;
    // if .kzb.cfg file exists then use the filename for the application.cfg data
    if(isKzbCfgFileAvailable) {
      AppCfgData = `BinaryName="${isKzbCfgFileAvailable}"\n`;
    } else if (isKzbFileAvailable) {
      AppCfgData = `BinaryName="${isKzbFileAvailable}"\n`;
    } else {
      console.error("Skip application.cfg Auto-generation: No kzb file available");
    }

    console.log(`Auto-generating application.cfg:\n${AppCfgData}`);
    const newFile = writeContentToFile(AppCfgData);
    return newFile;
  } else if (isAppCfgFileAvailable && !isAppCfgFileBinaryNameRowAvailable) {
    // loop through the file list to change the existing application.cfg
    for (let i = 0; i < fileList.length; i++) {
      const fileName = fileList[i].name;
      const file = fileList[i];

      if (fileName.toLowerCase() === "application.cfg") {
        let AppCfgData;
        // if .kzb.cfg file exists then use the filename for the application.cfg data
        if(isKzbCfgFileAvailable) {
          AppCfgData = `BinaryName="${isKzbCfgFileAvailable}"\n`;
        } else if (isKzbFileAvailable) {
          AppCfgData = `BinaryName="${isKzbFileAvailable}"\n`;
        } else {
          console.error("Skip application.cfg Auto-generation: No kzb file available");
        }

        // Read the content of the application.cfg file
        const fileContent = await readFileAsText(file);
        const newFileContent = `${AppCfgData}${fileContent}`;
        console.log(`BinaryName line is not present, adding content to the application.cfg:\n${newFileContent}`);
        const updatedFile = writeContentToFile(newFileContent);
        return updatedFile;
      }
    }
  } else {
    console.log("Skip application.cfg Auto-generation, user selected file for upload.");
    return null;
  }
}